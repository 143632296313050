import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Container } from '@material-ui/core'

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: "60px",
        paddingBottom: "60px",
        flexFlow: "column-reverse",
        [theme.breakpoints.up("sm")]: {
            flexFlow: "row",
        }
    },
    bottomParagraph: {
        marginTop: "20px",
        marginBottom: "32px",
    },
    text:{
        marginBottom: "0px",
        fontFamily: font,
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        color: "#282B3E",
    }
}));

const FooterContent = (props) => {
    
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Container>
                <Grid>
                    <p className={classes.bottomParagraph}>
                        <span className={classes.text}>
                            Access and use of this website is for authorized users only. If you have any questions regarding your policy or registration, please contact a John Hancock Vitality representative at 888-333-2659.
                        </span>
                        <br/>
                        <br/>
                        <span className={classes.text}>
                            Vitality is the provider of the John Hancock Vitality Program in connection with policies issued by John Hancock. Insurance products are issued by John Hancock Life Insurance Company (U.S.A.), Boston, MA 02116 (not licensed in New York) and John Hancock Life Insurance Company of New York, Valhalla, NY 10595.
                            <br/><br/>
                            MLINY052322199-1
                        </span>
                    </p>
                </Grid>
            </Container>
        </Grid>
    );
}

export default FooterContent;
