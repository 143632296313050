import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './Style';
import JsonConfig from '../../utils/jsonConfig.json';
import { MyContext } from '../../Context/MyContext';

export default function EncounteredError(props){

    let context = useContext(MyContext);
    let { appId } = context;
    
    let supportNumber = JsonConfig.call_center_number[appId] ? JsonConfig.call_center_number[appId] : JsonConfig.call_center_number.default;

    const classes = useStyles();
    return <Grid item md={9} xs={12}>
                <div className={classes.errorWrapper}>
                    <div className={classes.errorIcon} />
                    {props.systemErrorCount === 1
                        ? <span className={classes.errorLabelStyle}>
                            We have encountered an error. Please try again.
                        </span>
                        : <span className={classes.errorLabelStyle} >
                            We're sorry, we are currently experiencing some system issues. Please call {supportNumber} to speak with a John Hancock representative and we will assist you as best we can.
                        </span>}
                </div>
            </Grid>
}