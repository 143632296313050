import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import { TextInput, INPUT_VARIANT, ActionButton } from '@manulife/mux';
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { Button } from '@manulife/mux';
import EncounteredError from '../EncounteredError';
import { MyContext } from '../../Context/MyContext';
import Service from '../../Services/Service';
import Constants from '../../Utilities/Constants';
import { ReactComponent as Warning } from '../../images/warning_ic.svg';
import { getQueryParams } from '../../utils';
import JsonConfig from '../../utils/jsonConfig.json';
import InputError from '../InputError/InputError';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const gridSeparation = "gridSeparation";
const myinput = "myinput";
const onlyNumbers = "Only numbers are allowed";
const lastFourDigits = "Enter the last four digits of your policy";
const fullNineDigits = "Enter your full 9 digit Social Security number";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    thankYouText: {
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px",
        marginBottom: "25px"
    },
    subtitleText: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "34px",
        marginBottom: "0px"
    },
    inputContainer: {
        width: "45%",
        [theme.breakpoints.only("lg")]: {
            width: "35%",
        },
        [theme.breakpoints.only("md")]: {
            width: "45%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        }
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
    policyError: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        marginLeft: "10px",
        color: "#A00E18"
    },
    policyErrorContainer: {
        marginTop: "-16px",
        marginBottom: "14px"
    },
    gridLowMargin: {
        margin: "0px",
        paddingBottom: "5px !important"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    InLine: {
        display: displayStyle
    },
    InLineLabel: {
        display: displayStyle,
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "16px",
        paddingRight: "5px",
        color: "#282B3E"
    },
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "black",
        fontWeight: "600"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        color: "black",
        textDecorationColor: "#0000C1",
        lineHeight: "20px"
    },
    ctaWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "60px",
        "& a": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            "& a": {
                flexShrink: 0,
                marginRight: "1rem",
                marginBottom: 0
            }
        },
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    underlineWrapper: {
        display: "flex",
        paddingTop: "25px"
    },
    errorIcon: {
        float: "left",
        height: "17px",
        width: "17px",
        marginTop: "2px",
        color: "black !important",
        content: " ",
        background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',

    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    errorLabelStyle: {
        color: "#A00E18 !important",
        fontWeight: "300px",
        marginLeft: "5px"
    },
    errorWrapper: {
        paddingBottom: "40px"
    },
    errorMessage: {
        display: "flex",
        color: "#A00E18",
        marginTop: "0.5em",
        "&:before": {
            content: "''",
            display: displayStyle,
            flexShrink: 0,
            width: "29px",
            height: "17px",
            marginTop: "2px",
            background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',
        }
    },
    dateOfBirth: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",


    },
    errorSSNLabel: {
        marginLeft: "39px",
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "0.5em 0px 0px"
    },
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px",
        marginTop: "20px",
        alignItems: "center"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "20px"

    },
    buttonWrapper: {
        [theme.breakpoints.only("sm")]: {
            display: "flex"
        },
        [theme.breakpoints.only('xl')]: {
            display: "flex"
        },
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: "1rem",
            [theme.breakpoints.up("sm")]: {
                display: "unset",
                width: "auto",
                marginBottom: 0,
                marginLeft: "1.5rem",
                "&:first-child": {
                    marginLeft: 0
                }
            }
        }
    },
    textStyle: {
        fontFamily: font,
        fontSize: "22px",
        fontWeight: "500",
        lineHeight: "38px",
        color: "#000000"
    },
    confirmTextStyle: {
        fontFamily: font,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px"
    },
    inputWithIconContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        position: "relative"
    },
    actionButton: {
        position: "absolute",
        right: "0px",
    },
    wrongAttempts: {
        marginTop: '40px',
        width: '100%'
    }
}))

const VerifyIdentityPdco = props => {

    let {
        setPage,
        tokenInfo,
        setSessionTimeoutEnable,
        systemErrorCount,
        setSystemErrorCount,
        setProductList,
        errorType,
        maintenanceDetails,
        setErrorScenario,
        setReview,
        setRedirectUrl
    } = props;

    let context = useContext(MyContext);
    let { appId } = context;
    let { jwt } = getQueryParams();

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    // const [isPolicyVarificationFailed, setPolicyVarificationFailed] = useState(false);    

    const [formValues, setFormValues] = useState({
        policyNumber: '',
        ssn: ''
    })
    const [errorState, setErrorState] = useState({
        policyNumber: { id: 'policyNumber', errMsg: '', isError: false, warning: false },
        ssn: { id: 'ssn', errMsg: '', isError: false, warning: false },
        wrongattempts: { id: 'wrongattempts', errMsg: '', isError: false, warning: false },
    })

    const [allValues, setAllValues] = useState({
        ssnVisible: false,
        policyNumberVisible: false
    });

    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
    }

    useEffect(() => {
        setSessionTimeoutEnable(true)
    });

    const submit = () => {
        if (!isLoading && validate()) {
            // setPolicyVarificationFailed(false);
            resetPolicyError();
            callValidatePolicy();
        }
    }

    function callValidatePolicy() {

        if (systemErrorCount > 0) setSystemErrorCount(0);

        let policyNumber = formValues.policyNumber?.trim();
        let ssn = formValues.ssn?.trim();
        if (appId === JsonConfig.app_id.PDCO && policyNumber.length > 4) {
            policyNumber = policyNumber.substring(policyNumber.length - 4);
        }



        let payload = {
            "token": jwt,
            "guid": tokenInfo.requestid,
            "appid": appId,
            "policynumber": policyNumber,
            "ssn": ssn
        }
        setLoading(true);
        Service.verifyDetails(payload)
            .then((response) => {
                setLoading(false)

                let systemErrorCodes = [Constants.CODE_9002, Constants.CODE_9999, Constants.CODE_9014];
                let reviewErrorPageCodes = [Constants.CODE_9060, Constants.CODE_9075];

                if (response.code === Constants.CODE_2001) {
                    setProductList(response?.details?.identity);
                    setRedirectUrl(response.details.postUrl)
                    setPage(Constants.PAGE_SUCCESS_PAGE_PDCO);
                    resetPolicyError();
                    setSystemErrorCount(0);
                } else if (reviewErrorPageCodes.includes(response.code)) {
                    setReview(true);
                    setErrorScenario(true);
                } else if (response.code === Constants.CODE_9009) {
                    setReview(false)
                    setErrorScenario(true);
                } else if (response.code === Constants.CODE_9059) {
                    setSystemErrorCount(0);
                    setErrorState({ ...errorState, wrongattempts: { id: 'wrongattempts', errMsg: "One or more fields contains incorrect information. Please review your entries and try again.", isError: true, warning: false } });
                    setFormValues({ ...formValues, "policyNumber": "", "ssn": "" });
                } else if (systemErrorCodes.includes(response.code)) {
                    setSystemErrorCount(systemErrorCount + 1);
                    resetPolicyError();
                } else {
                    // Unhandled error codes
                    setSystemErrorCount(systemErrorCount + 1);
                    resetPolicyError();
                }
            }).catch(error => {
                console.log("Error ========>", error);
                setLoading(false);
                setSystemErrorCount(systemErrorCount + 1);
                // setPolicyVarificationFailed(false);
                resetPolicyError();
            })
    }

    function resetPolicyError() {
        setErrorState({ ...errorState, policyNumber: { id: 'policyNumber', errMsg: "", isError: false, warning: false } });
    }

    function validate() {
        const { policyNumber, ssn } = formValues;
        let no_error = true;

        // Validate policyNumber
        if (!/^\d*$/.test(policyNumber)) {
            setErrorState(prevState => ({ ...prevState, policyNumber: { id: 'policyNumber', errMsg: onlyNumbers, isError: true, warning: false } }));
            no_error = false;
        }
        if (policyNumber.length !== 4) {
            setErrorState(prevState => ({ ...prevState, policyNumber: { id: 'policyNumber', errMsg: lastFourDigits, isError: true, warning: false } }));
            no_error = false;
        } else {
            setErrorState(prevState => ({ ...prevState, policyNumber: { id: 'policyNumber', errMsg: "", isError: false, warning: false } }));
        }

        // Validate ssn
        if (!/^\d*$/.test(ssn)) {
            setErrorState(prevState => ({ ...prevState, ssn: { id: 'ssn', errMsg: onlyNumbers, isError: true, warning: false } }));
            no_error = false;
        }
        if (ssn.length !== 9) {
            setErrorState(prevState => ({ ...prevState, ssn: { id: 'ssn', errMsg: fullNineDigits, isError: true, warning: false } }));
            no_error = false;
        } else {
            setErrorState(prevState => ({ ...prevState, ssn: { id: 'ssn', errMsg: "", isError: false, warning: false } }));
        }

        return no_error;
    }

    const classes = useStyles();

    const changeHandler = (name, value) => {
        const itIsNumber = /^\d*$/.test(value);

        if (!itIsNumber) {
            //do  not update state if it is not a number
            setErrorState({ ...errorState, [name]: { id: name, errMsg: onlyNumbers, isError: true, warning: false } });
            return;
        }

        if (name === "policyNumber") {
            if (value.length > 4) {
                value = value.substring(0, 4);
                setErrorState({ ...errorState, policyNumber: { id: 'policyNumber', errMsg: "Enter only the last four digits of your policy", isError: true, warning: false } });
            } else if (value.length < 4) {
                setErrorState({ ...errorState, policyNumber: { id: 'policyNumber', errMsg: lastFourDigits, isError: true, warning: false } });
            } else if (value.length === 4) {
                setErrorState({ ...errorState, policyNumber: { id: 'policyNumber', errMsg: "", isError: false, warning: false } });
            }
        }

        if (name === "ssn") {
            if (value.length > 9) {
                value = value.substring(0, 9);
                setErrorState({ ...errorState, ssn: { id: 'ssn', errMsg: fullNineDigits, isError: true, warning: false } });
            } else if (value.length < 9) {
                setErrorState({ ...errorState, ssn: { id: 'ssn', errMsg: fullNineDigits, isError: true, warning: false } });
            } else if (value.length === 9) {
                setErrorState({ ...errorState, ssn: { id: 'ssn', errMsg: "", isError: false, warning: false } });
            }
        }

        if (formValues.ssn.length === 9 && formValues.policyNumber.length === 4) {
            setErrorState({
                ssn: { id: 'ssn', errMsg: "", isError: false, warning: false },
                policyNumber: { id: 'policyNumber', errMsg: "", isError: false, warning: false },
                wrongattempts: { id: 'wrongattempts', errMsg: "", isError: false, warning: false }
            });
        }

        setFormValues({ ...formValues, [name]: value });

        setIsSubmitDisabled(hasErrors());
    };

    const hasErrors = () => {
        return Object.values(errorState).some((error) => error.isError);
    }

    return (
        <div data-testid="verifyIdentity-root" className={classes.root}>
            <Grid container spacing={0}>
                {
                    systemErrorCount > 0 &&
                    <EncounteredError appId={appId} systemErrorCount={systemErrorCount} />
                }
                {
                    errorType === 'Maintenance' &&
                    <Grid item md={9} xs={12}>
                        <div className={classes.errorWrapper}>
                            <div className={classes.errorIcon} />
                            <span className={classes.errorLabelStyle}>
                                {
                                    maintenanceDetails ? maintenanceDetails : "The system is currently undergoing scheduled maintenance. If you are experiencing registration issues, please try again later. Thank you for your patience."
                                }
                            </span>
                        </div>
                    </Grid>
                }
                <Grid item md={9} xs={12} className={classes.gridSeparation}>
                    <div className={classes.thankYouText}>Thank you{tokenInfo?.firstname ? ", " + tokenInfo.firstname : ""}</div>
                    <div className={classes.subtitleText}>Your username <strong>{tokenInfo?.username ? tokenInfo.username : ""}</strong> has been created. Your security is our top priority, so please<br />complete an additional verification step below.</div>
                </Grid>

                <Grid item md={12} xs={12} className={classes.gridSeparation}>
                    <ul>
                        <li>
                            <p className={classes.textStyle}><strong>Last 4 digits of your policy number -</strong> you can find this in your welcome email</p>
                        </li>
                        <li>
                            <p className={classes.textStyle}><strong>Full social security number - </strong>enter the full 9 digits of your social security number</p>
                        </li>
                    </ul>
                </Grid>
                <div className={classes.inputContainer}>
                    <Grid item md={12} xs={12} className={gridSeparation + " " + myinput + (errorState.policyNumber.isError && !errorState.policyNumber.warning ? " error" : "")}>
                        <label className={classes.labelStyle}>
                            Policy number
                        </label>
                        <div className={classes.inputWithIconContainer}>
                            <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                                id='policyNumber'
                                type="text"
                                data-testid="verifyIdentity-policyNumber"
                                placeholder="Enter the last 4 digits of your policy number"
                                maxLength={50}
                                value={formValues.policyNumber}
                                onChange={e => {
                                    console.log("key press");
                                    changeHandler("policyNumber", e)
                                }}
                                onBlur={e => changeHandler("policyNumber", e)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") submit();
                                }}
                                callouts={true}
                                customStyle={{
                                    rootStyle: {
                                        width: "100%",
                                    },
                                    inputStyle: {
                                        padding: '0px'

                                    }
                                }} name="policyNumber" />
                        </div>
                        {errorState.policyNumber.isError && <InputError errorState={errorState} inputId="policyNumber" showIcon={true} />}
                    </Grid>
                    <Grid item md={12} xs={12} className={myinput + (errorState.ssn.isError && !errorState.ssn.warning ? " error" : "")}>
                        <label className={classes.labelStyle}>
                            Social security number
                        </label>
                        <div className={classes.inputWithIconContainer}>
                            <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                                id='ssn'
                                type={allValues.ssnVisible ? "text" : "password"}
                                data-testid="verifyIdentity-ssn"
                                placeholder="Enter your full social security number "
                                maxLength={50}
                                value={formValues.ssn}
                                onChange={e => changeHandler("ssn", e)}
                                onBlur={e => changeHandler("ssn", e)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") submit();
                                }}
                                callouts={true}
                                customStyle={{
                                    rootStyle: {
                                        width: "100%",
                                    },
                                    inputStyle: {
                                        padding: '0px',
                                        fontFamily: allValues.ssnVisible ? "inherit" : "none"

                                    }
                                }} name="ssn" />
                            <div className={classes.actionButton}>
                                <ActionButton
                                    ariaLabel="Edit"
                                    icon={allValues.ssnVisible ? <Eye2 color="#282B3E" />
                                        : <Eye1 color="#282B3E" />
                                    }
                                    onClick={e => handleShow("ssnVisible")}
                                />
                            </div>
                        </div>
                        {errorState.ssn.isError && <InputError errorState={errorState} inputId="ssn" showIcon={true} />}
                    </Grid>
                </div>
                {errorState.wrongattempts.isError &&
                        <div className={classes.wrongAttempts}>
                            <InputError errorState={errorState} inputId="wrongattempts" showIcon={true} />
                        </div>
                    }
                <Grid item md={12} xs={12} className={classes.ctaWrapper}>
                    <Button
                        name="submit button"
                        data-testid="verifyIdentity-submitButton"
                        saving={isLoading}
                        id="submitbutton"
                        disabled={isLoading}
                        className=""
                        onClick={e => submit()}>Submit</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default VerifyIdentityPdco