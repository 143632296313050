import axios from 'axios';

const deleteUser = (guid, serviceType) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/deleteUser` : 'http://localhost:8080/deleteUser',
        {
            "Guid": guid,
            "ServiceType": serviceType
        },
        { headers }).then(response => {
            
            if (response.data.code === "200") {
                console.log(response.data.message)
            } else {
                console.log(response.data.code + ": User deletion failed")
            }
        }).catch(error => {
            console.log("Error ========>", error);
        })
}

export default deleteUser;