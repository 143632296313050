import Utility from "../Utilities/Utility";
import { getQueryParams } from '../utils';

const useAdobeDataLayer = () => {
  const pageURL = window.location.href;
  const siteSection = "registration";
  const pageNamePrefix = "insurance:vitality:johnhancockinsurance:";

  const events = {
    registrationStart: "registrationStart",
    registrationComplete: "registrationComplete",
    pageLoad: "pageLoad",
    registrationError: "registrationError",
  };

  const pageNames = {
    step1: "Registration - Your Information",
    step2: "Create Password",
    step3: "Verify MFA",
    accountAlreadyExists: "Account Alread Exists",
    unverifiedUser: "Verification Policy",
    registrationComplete: "Registration Complete",
  };

  const adobeActiveApps = {
    27096999: true,
    27096405: true,
  };

  //   For Reference
  //   window.location.href: 'http://localhost:3000/auth/login?appid=362772';
  //   window.location.origin: 'http://localhost:3000';
  //   window.location.pathname: '/auth/login';

  const generateAppid = () => {
    let { appId } = getQueryParams();
    return appId;
  };

  const pushHandler = (eventObject) => {
    const appId = generateAppid();
    const isActiveByApp = adobeActiveApps[appId];
    const isActiveByEnv = process.env.REACT_APP_DATA_LAYER === "enabled";
    if (!isActiveByEnv || !isActiveByApp) return; // For now, this feature shall not be triggered for production environment

    if (process.env.REACT_APP_DATA_LAYER === "disabled") return; // For now, this feature shall not be triggered for production environment
    const analyticLayer = window.adobeDataLayer;
    if (!analyticLayer) window.adobeDataLayer = [];

    window.adobeDataLayer.push(eventObject);
  };

  const addAdobeScript = () => {
    const appId = generateAppid();
    const isActiveByApp = adobeActiveApps[appId];
    const isActiveByEnv = process.env.REACT_APP_DATA_LAYER === "enabled";
    console.log("addAdobeScript:isActiveByEnv==", isActiveByEnv, isActiveByApp)
    if (isActiveByEnv && isActiveByApp) {
      console.log("Loading Datalayer Script...");
      const dlScript = document.createElement("script");
      dlScript.src = process.env.REACT_APP_ADOBE_SCRIPT_URL;
      dlScript.async = true;
      window.document.head.appendChild(dlScript);
    }
  };

  // This event needs to fire when the user lands on the page mention below where the registration starts (after the user and pageLoad event)
  const registrationStart = () => {
    pushHandler({
      event: events.registrationStart,
      page: {
        pageName: `${pageNamePrefix} ${pageNames.step1}`,
        siteSection,
        pageURL,
      },
    });
  };

  // This event needs to fire when the user successfully registers (This can be via API call or a pageview of the confirmation page)
  const registrationComplete = async (userID = "") => {
    // hashSHA256
    const hashedUsername = await Utility.hashSHA256(userID);
    pushHandler({
      event: events.registrationComplete,
      page: {
        pageName: `${pageNamePrefix} ${pageNames.registrationComplete}`,
        siteSection,
        pageURL,
      },
      user: {
        userID: hashedUsername,
      },
    });
  };

  // "This pageview events needs to fire when the user lands on the pages mentioned
  const pageLoad = (pageName = "") => {
    pushHandler({
      event: events.pageLoad,
      page: {
        pageName: `${pageNamePrefix} ${pageNames[pageName]}`,
        siteSection,
        pageURL,
      },
    });
  };

  //   Error Types are as below
  //   incorrect-user-password-combination
  //   third-attempt-failature-setup
  //   thrird-attempt-failature-verify
  //   user-session-expired
  const registrationUnsuccessfull = ({
    errorType,
    errorCode,
    errorDescription = "Something went wrong!",
  }) => {
    pushHandler({
      event: events.registrationError,
      error: {
        errorType,
        errorCode,
        errorCategory: "registration-error",
        errorDescription,
      },
      page: {
        pageName: `${pageNamePrefix} ${pageNames.step3}`,
        siteSection,
        pageURL,
      },
    });
  };

  return {
    registrationStart,
    registrationComplete,
    pageLoad,
    registrationUnsuccessfull,
    addAdobeScript,
  };
};

export default useAdobeDataLayer;
