
export function isBlank(item){
    return item === ''
}

export function isNull(item){
    return item == null
}

export function camelize(str) {
    if(isNull(str)) return "";

    return str.split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
  }

export function getQueryParams () {
  const search = new URLSearchParams(window.location.search);
  return {
    jwt: search.get('jwt') || null,
    appId: search.get('appId') || null
  };
};

export function redirectTo (redirectUrl, expectedUrls) {
  let isValidUrl = false;
  for (let i = 0; i < expectedUrls.length; i++) {
    const expectedUrl = expectedUrls[i];
    if(redirectUrl.startsWith(expectedUrl)){
      isValidUrl = true;
      break;
    }
  }
  if(isValidUrl){
    window.open(redirectUrl, "_self", "noreferrer", "noopener");
  }
};
  
function sanitize(string) {    
  if(string == null) return string;

  const createDOMPurify = require('dompurify');
  const DOMPurify = createDOMPurify(window);
  string = DOMPurify.sanitize(string);
  return string;
}

async function hashSHA256(stringInput) {
  // create a TextEncoder instance, used to encode string to Unit8Array (byte array)
  const encoder = new TextEncoder();

  // encode string to Unit8Array
  const encoded = encoder.encode(stringInput);

  // use the subtleCrypto api to generate SHA-256 hash for the encoded variable
  const hashBuffer = await crypto.subtle.digest('SHA-256', encoded);

  // convert ArrayBuffer to a Uint8Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // map each byte into Uint8Array to a hexaDecimal string and ensuring each byte is 2 characters long
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}

const Utility = {
 isBlank, isNull, camelize, getQueryParams, redirectTo, sanitize, hashSHA256
}

export default Utility;