export let VITALITY_LOGO = require("../images/vitality_logo.png"); 
export let RedWarning = require("../images/red_warning.png");
export let GrayWarning = require("../images/gray_warning.png");
export let HomeIc = require("../images/home_ic.png");
export let vitalityQRCode = require("../images/vitality_app_qr_code.png");
export let VitalityPlayStoreLogo = require("../images/vitality_play_store_logo.png");
export let VitalityAppStoreLogo = require("../images/vitality_app_store_logo.png");

const Images = {
    VITALITY_LOGO, RedWarning, GrayWarning, HomeIc, vitalityQRCode, VitalityPlayStoreLogo, VitalityAppStoreLogo
}

export default Images;