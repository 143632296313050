
export function isBlank(item){
    return item === ''
}

export function isNull(item){
    return item == null
}

export function camelize(str) {
    if(isNull(str)) return "";

    return str.split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
  }

export function getQueryParams () {
  const search = new URLSearchParams(window.location.search);
  return {
    jwt: search.get('jwt') || null,
    appId: search.get('appId') || null
  };
};

export function redirectTo (redirectUrl, expectedUrls) {
  let isValidUrl = false;
  for (let i = 0; i < expectedUrls.length; i++) {
    const expectedUrl = expectedUrls[i];
    if(redirectUrl.startsWith(expectedUrl)){
      isValidUrl = true;
      break;
    }
  }
  if(isValidUrl){
    window.open(redirectUrl, "_self", "noreferrer", "noopener");
  }
};
  
function sanitize(string) {    
  if(string == null) return string;

  const createDOMPurify = require('dompurify');
  const DOMPurify = createDOMPurify(window);
  string = DOMPurify.sanitize(string);
  return string;
}

const Utility = {
 isBlank, isNull, camelize, getQueryParams, redirectTo, sanitize
}

export default Utility;