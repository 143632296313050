import React from 'react'
import { makeStyles, Grid } from '@material-ui/core';
import { RedWarning, GrayWarning } from '../../utils/Images';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({
    
    errorTextRed: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        marginLeft: "8px",
        color: "#A00E18"
    },    
    errorTextGray: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        marginLeft: "8px",
        color: "#5E6073"
    },
    errorContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "8px",
    }    
}))

const InputError = (props) => {

    const classes = useStyles();
    let {errorState, inputId, showIcon} = props;

    return (
            <Grid item md={12} xs={12} className={classes.errorContainer}>
                {
                    showIcon &&
                    <img src={errorState[inputId].warning ? GrayWarning: RedWarning} alt="warning"/>
                }
                <span className={errorState[inputId].warning ? classes.errorTextGray : classes.errorTextRed}> {errorState[inputId].errMsg}</span>
            </Grid>
    );
}

export default InputError;
