export const SUCCESS_CODE_0000 = "0000";
export const CODE_2001 = "2001";
export const CODE_2002 = "2002";
export const CODE_2003 = "2003";
export const CODE_9007 = "9007";
export const CODE_9008 = "9008";
export const CODE_9009 = "9009";
export const CODE_9011 = "9011";
export const CODE_9057 = "9057";

export const CODE_9060 = "9060";
export const CODE_9002 = "9002";
export const CODE_9059 = "9059";
export const CODE_9999 = "9999";
export const CODE_9014 = "9014";
export const CODE_9075 = "9075";
export const CODE_9077 = "9077";

export const PAGE_VERIFY_DETAILS_0 = 0;
export const PAGE_SUCCESS_PAGE_1 = 1;

export const APP_ID_DTC = "2320147";
export const APP_ID_LTC = "266976";
export const APP_ID_LIFE = "362772";
export const APP_ID_VITALITY = "27096999";
export const CR2O_EXPECTED_URLS = [
    "https://registration.johnhancock.com",
    "https://registration-uat.johnhancock.com",
    "https://centralregistry.jhapps-uat.aks.manulife.com",
    "https://centralregistry.jhapps-tst.aks.manulife.com",
    "https://centralregistry.jhapps-dev.aks.manulife.com"
];


const Constants = {
    SUCCESS_CODE_0000,
    CODE_2001,
    CODE_2002,
    CODE_2003,
    CODE_9007,
    CODE_9008,
    CODE_9009,
    CODE_9011,
    CODE_9057,
    CODE_9060,
    CODE_9002,
    CODE_9059,
    CODE_9999,
    CODE_9014,
    CODE_9075,
    CODE_9077,
    
    PAGE_VERIFY_DETAILS_0,
    PAGE_SUCCESS_PAGE_1,

    APP_ID_DTC,
    APP_ID_LTC,
    APP_ID_LIFE,
    APP_ID_VITALITY,

    CR2O_EXPECTED_URLS
};

export default Constants;