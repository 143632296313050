export function equalsIgnoreCase(a, b) {
  return a?.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
}

export function classList(classes) {
  if (!classes) {
    return '';
  }

  return Object
    .entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(' ');
}
