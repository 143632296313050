// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffffaa;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}


.progress-container{
    display: flex;
    align-items: center;
    background-color: #ffffffaa;
}
.loading-text{
    font-family: Manulife JH Sans;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-left: 20px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/style/LoaderComponentStyle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".loader-root{\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    background-color: #ffffffaa;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 99999;\n}\n\n\n.progress-container{\n    display: flex;\n    align-items: center;\n    background-color: #ffffffaa;\n}\n.loading-text{\n    font-family: Manulife JH Sans;\n    font-size: 16px;\n    line-height: 20px;\n    display: inline-block;\n    margin-left: 20px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
