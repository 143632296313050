import URLConstants from '../utils/URLConstants';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND ? process.env.REACT_APP_BACKEND : "http://localhost:8080";
const isLocalhost = window.location.href.includes('localhost');


const sendRequest = (endpoint, method, payload) => {

    let _headers = {}
    
    if(payload){
        _headers["Content-Type"] = "application/json";
    }

    const serviceUrl = BACKEND_URL+endpoint;
    const options = {
        url: serviceUrl,
        method: method || 'GET',
        headers: _headers,
        mode: isLocalhost ? 'cors' : 'same-origin',
        data: typeof payload === 'string' ? payload : JSON.stringify(payload)
    }
    return call(options);
}

async function call(options){

    return new Promise((resolve, reject)=> {
        axios(options)
            .then(response => {    
                let status = response.status;                
                if(status <300){
                    return response.data;
                } else{
                    return {"code": status, "message": "Something went wrong, api failed to return response!"};
                }    
            }).then(res=> {
                resolve(res);
            }).catch(e => {
                let msg = e.message === "Network Error" ? "Network Error" : "Something went wrong, cought unknown exception."
                reject({"code": 500, "message": msg});
            });
    });
}

const Service = {
    getRegSrc: (payload)=> sendRequest(URLConstants.GET_REG_SOURC_API, "POST", payload),
    verifyDetails: (payload)=> sendRequest(URLConstants.VERIFY_DETAILS_API, "POST", payload),
    checkMintenance: ()=> sendRequest(URLConstants.MESSAGE_API, "POST", {}),
    postCall: ()=> sendRequest(URLConstants.POST_CALL_API, "POST", {}),
}

export default Service;