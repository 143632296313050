import Utility from "../Utilities/Utility";

export const getQueryParams = () => {
  const search = new URLSearchParams(window.location.search);
  
  let appId = Utility.sanitize(search.get('appId'));
  let jwt = Utility.sanitize(search.get('jwt'));

  return {
    appId: appId,
    jwt: jwt,
  };
};