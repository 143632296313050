import React, { useState, useEffect } from 'react';
import {
    postCall
} from '../../api';
import { getQueryParams } from '../../utils';
import {
    ErrorPage,
    SessionExpiredPage,
    MaintenancePage,
    RegisteredPage
} from '../../pages';
import { useIdleTimer } from 'react-idle-timer'
import {initJHChatBotBundle, loadChatBot} from '../../scripts/ChatBotScript';
import JsonConfig from '../../utils/jsonConfig.json';
import VerifyIdentity from '../VerifyIdentity';
import Service from '../../Services/Service';
import Constants from '../../Utilities/Constants';
import LoginPage from '../LoginPage/LoginPage';
import LoaderComponent from '../LoaderComponent/LoaderComponent';
import Utility from '../../Utilities/Utility';


const PageBody = () => {
    
    const [page, setPage] = useState(Constants.PAGE_VERIFY_DETAILS_0);

    const [errorScenario, setErrorScenario] = useState(false);
    const [productList, setProductList] = useState([]);
    const [loginHook, setLoginHook] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false);
    const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(false)

    const [tokenInfo, setTokenInfo] = useState('');
    // const [tokenInfo, setTokenInfo] = useState({"requestid": "234567", "firstname": "Test", "username": "Test1234"});
    const [errorType, setErrorType] = useState('')
    const [maintenanceDetails, setMaintenanceDetails] = useState("")
    const [isLoading, setLoading] = useState(false);
    const [isFullScreenLoading, setFullScreenLoading] = useState(true);
    const [systemErrorCount, setSystemErrorCount] = useState(0);
    const [isReview, setReview] = useState(false);
    
    let { jwt, appId } = getQueryParams();

    useEffect(()=> {        
        if(appId !== JsonConfig.app_id.DTC && process.env.REACT_APP_ENABLE_CHATBOT === "enabled" && window.isDOMContentLoaded && appId){
            let appName = JsonConfig.app_name[appId];
            let lob = JsonConfig.lob[appId]; 
            
            initJHChatBotBundle(()=> {
                let data = {
                    lob: lob,
                    appId: appId,
                    channelData: appName,
                }
                loadChatBot(data);
            });
        }

    }, [window.isDOMContentLoaded, appId]);

    useEffect(()=> {
        if(jwt && appId){
            callGetRegSrc();
        } else{
            setSystemErrorCount(1);
            setFullScreenLoading(false);
        }
    }, [appId]);

    function callGetRegSrc(){
        
        if(systemErrorCount > 0)
        {            
            setSystemErrorCount(0);
        }
        let payload = {
            "appid": appId,
            "jwt": jwt
        };
        Service.getRegSrc(payload)
        .then((response)=> {
            setFullScreenLoading(false)
            if(response.maintenancestatus !== "Active"){
                setErrorType(response.maintenancestatus);
                setMaintenanceDetails(response.maintenancemessage);
                if(response.maintenancestatus === "Technical") return;
            }

            let errorPageCodes = [Constants.CODE_9057, Constants.CODE_9011];
            let reviewErrorPageCodes = [Constants.CODE_9060, Constants.CODE_9075];
            let systemErrorCodes = [Constants.CODE_9007, Constants.CODE_9008, Constants.CODE_9009, Constants.CODE_2003, Constants.CODE_9077];
            let redirectCodes = [Constants.CODE_2001, Constants.CODE_2002];
            
            if (response.code === Constants.SUCCESS_CODE_0000) {
                setTokenInfo(response.details);  
            } else if (errorPageCodes.includes(response.code)) { 
                setReview(false);
                setErrorScenario(true);
            } else if(reviewErrorPageCodes.includes(response.code)){
                setReview(true);
                setErrorScenario(true);
            }else if (systemErrorCodes.includes(response.code)) { 
                setSystemErrorCount(systemErrorCount+1);
            } else if(redirectCodes.includes(response.code)){ 
                let cr2oURL = process.env.REACT_APP_CR2O_URL+appId;
                Utility.redirectTo(cr2oURL, Constants.CR2O_EXPECTED_URLS);
            } else { 
                // Unhandled error codes
                setSystemErrorCount(systemErrorCount+1);
            }
        }).catch(error => {
            setFullScreenLoading(false);
            console.log("Error ========>", error);
            if (error.message === "Network Error") {
                setErrorType("Technical")
            }
        })
    }
    
    const handleOnIdle = e => {
        if(sessionTimeoutEnable){
            setSessionExpired(true)
            return
        }
    }

    // 15 min session timeout if in Idle mode
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })
    

    return (
        <div>   
            {
                isFullScreenLoading &&
                <LoaderComponent />
            }
            {
                sessionExpired ?
                    <SessionExpiredPage appId={appId} setSessionTimeoutEnable={setSessionTimeoutEnable} setSessionExpired={setSessionExpired}/> :
                errorType === 'Technical' ?
                    <MaintenancePage errorType={errorType} setSessionTimeoutEnable={setSessionTimeoutEnable} maintenanceDetails={maintenanceDetails}/> :
                errorScenario ?
                    <ErrorPage isReview={isReview} appId={appId} setSessionTimeoutEnable={setSessionTimeoutEnable}/> :
                loginHook ?
                    <LoginPage setSessionTimeoutEnable={setSessionTimeoutEnable} /> :
                
                page === Constants.PAGE_VERIFY_DETAILS_0 ?
                    <VerifyIdentity
                        appId={appId} 
                        maintenanceDetails={maintenanceDetails}
                        errorType={errorType}
                        systemErrorCount={systemErrorCount}
                        setSystemErrorCount={setSystemErrorCount}
                        setPage={setPage}
                        tokenInfo={tokenInfo}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        setProductList={setProductList}
                        setErrorScenario={setErrorScenario}
                        setReview={setReview}/> :
                
                page === Constants.PAGE_SUCCESS_PAGE_1 ?
                    <RegisteredPage
                        appId={appId}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        isLoading={isLoading}
                        postCall={() => postCall(tokenInfo.username, appId, setLoginHook, setLoading, setSystemErrorCount, systemErrorCount)}
                        productList={productList}
                        systemErrorCount={systemErrorCount}/> :
                <React.Fragment />
            }
        </div>
    )
}

export default PageBody;
