import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    textLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    contactLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    closeLabelStyles: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "13px",
        lineHeight: "18px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    contactSeparation: {
        paddingBottom: "20px"
    }
}));

const MaintenancePage = props => {
    const classes = useStyles();

    useEffect(()=> {
        props.setSessionTimeoutEnable(false)
    })

    return (
        <div>
            <Grid container>
                <Grid item md={8} xs={12} className={classes.gridSeparation}>
                    {
                        props.errorType === 'Maintenance' ? <span className={classes.errorHeaderStyles}>
                                We are currently down for scheduled maintenance.
                            </span> : <span className={classes.errorHeaderStyles}>
                                We are currently experiencing technical issues.
                            </span>
                    }
                </Grid>
                <Grid item md={8} xs={12} className={classes.gridSeparation}>
                    {
                        props.errorType === 'Maintenance' ? <span className={classes.subheaderStyles}>
                                {props.maintenanceDetails}
                            </span> : <span className={classes.subheaderStyles}>
                                But we are working as quickly as possible to restore our services and apologize for any inconvenience.
                            </span>
                    }
                </Grid>
            </Grid>
            
        </div>
    );
};

export default MaintenancePage;