
export function isBlank(item){
    return item === ''
}

export function isNull(item){
    return item == null
}

export function redirectUrl(url){
    window.location.href=url;
}

export function scrollToTop(){
    let ele = document.getElementById("header-root");
    ele?.scrollIntoView();
}

const Utility = {
 isBlank, isNull, redirectUrl, scrollToTop
}

export default Utility;