import React, { useContext, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@manulife/mux';
import Constants from '../../Utilities/Constants';
import { MyContext } from '../../Context/MyContext';
import JsonConfig from '../../utils/jsonConfig.json';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    loginHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    loginBodyStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    }
}))


const LoginPage = props => {

    let { setSessionTimeoutEnable } = props;

    let context = useContext(MyContext);
    let { appId } = context;
    
    const classes = useStyles()

    useEffect(()=> {
        setSessionTimeoutEnable(true)
    })

    const redirectToLogin = () => { 
        let loginUrlVariable = "REACT_APP_LOGIN_URL_"+JsonConfig.login_url_postfix[appId];
        let loginUrl = process.env[loginUrlVariable] || JsonConfig.static_redirect_url.default;        
        window.location.href = loginUrl;
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridSeparation}>
                <Grid item xs={8}>
                    <h1 className={classes.loginHeaderStyles}>
                        It appears we are experiencing technical difficulties at this time. Please try to log in again later.
                    </h1>
                </Grid>
            </Grid>
            <Grid container spacing={6} className={classes.gridSeparation}>
                <Grid item xs={12} md={8} >
                    <p className={classes.loginBodyStyles}>
                        Please sign in using your ID associated with your account.
                    </p>
                </Grid>
                <Grid item xs={12} md={8} >
                    <Button
                        customStyle={{
                            buttonStyle: {
                                width: "148px",
                                height: "60px"
                            }
                        }}
                        name="RedirectToLoginButton"
                        id="RedirectToLoginButton"
                        onClick={e => redirectToLogin()}><div className={classes.buttonTextStyles}>Sign In</div></Button> 
                </Grid>
            </Grid>
        </div>
    )
}

export default LoginPage
