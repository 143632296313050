import chatBotTokenService from "../api/chatBotTokenService";

export async function loadChatBot(data) {
        let chatBotLOB = data.lob;
        console.log("In loadChatBot: chatBotLOB==", chatBotLOB);
        if(chatBotLOB == null){
        window.document.getElementById("chatBotDiv").style.display = "none";
        return;
    }   

    try {        
        let chatSessionToken = await chatBotTokenService(data, chatBotLOB);
                if(!chatSessionToken){
            window.document.getElementById("chatBotDiv").style.display = "none";
            return;
        }
        window.document.getElementById("chatBotDiv").style.display = "block";
    
        /** Init instance of WebChat UI **/
        let jhwebchat = new window.JHWebChat({
            webchatmode: 'bot',
            directlinetoken: chatSessionToken,
            channeldata: 'LineOfBusiness:'+data.channelData, // 'LTC' or 'LIFE' or 'Vitality, etc'
            livechatenv: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev' // 'dev' or 'prod'
        });
        window.jhwebchat = jhwebchat;
            
        const existingScript = document.getElementById('chatBotScript');
                if (!existingScript) {
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.id = 'chatBotScript';
            window.document.head.appendChild(script);
        }
    } catch (error) {
        console.log("In loadChatBot:error==", error);
    }
};

export function initJHChatBotBundle (callback) {
    const existingScript = document.getElementById('JHChatBotScript');
    if (!existingScript) {
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.id = 'JHChatBotScript';
        script.src = "https://partnerlink.jhancock.com/js/jh-webchat.bundle.js";
        script.onload = function(){
            callback();
        }
        window.document.head.appendChild(script);
    }
};

const ChatBotScript = {
    initJHChatBotBundle, 
    loadChatBot
}

export default ChatBotScript;