import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import {TextInput, INPUT_VARIANT} from '@manulife/mux';
import { Button } from '@manulife/mux';
import { Progress } from '@manulife/mux';
import EncounteredError from '../../components/EncounteredError';
import { MyContext } from '../../Context/MyContext';
import Service from '../../Services/Service';
import Constants from '../../Utilities/Constants';
import { ReactComponent as Warning } from '../../images/warning_ic.svg';
import { getQueryParams } from '../../utils';
import JsonConfig from '../../utils/jsonConfig.json';
import InputError from '../InputError/InputError';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const gridSeparation = "gridSeparation";
const myinput = "myinput";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    thankYouText:{
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px",
        marginBottom: "25px"
    },
    subtitleText:{
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "34px",
        marginBottom: "40px"
    },
    inputContainer:{
        width: "45%",
        [theme.breakpoints.only("lg")]: {
            width: "35%",
        },
        [theme.breakpoints.only("md")]: {
            width: "45%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "80%",
        }
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
    policyError: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        marginLeft: "10px",
        color: "#A00E18"
    },
    policyErrorContainer:{
        marginTop:"-16px",
        marginBottom: "14px"
    },
    gridLowMargin: {
        margin: "0px",
        paddingBottom: "5px !important"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    InLine: {
        display: displayStyle
    },
    InLineLabel: {
        display: displayStyle,
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "16px",
        paddingRight: "5px",
        color: "#282B3E"
    },
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "black",
        fontWeight: "600"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        color: "black",
        textDecorationColor: "#0000C1",
        lineHeight: "20px"
    },
    ctaWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& a": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            "& a": {
                flexShrink: 0,
                marginRight: "1rem",
                marginBottom: 0
            }
        },
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",
            marginTop: "20px",
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    underlineWrapper: {
        display: "flex",
        paddingTop: "25px"
    },
    errorIcon: {
        float: "left",
        height: "17px",
        width: "17px",
        marginTop: "2px",
        color: "black !important",
        content: " ",
        background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',

    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    errorLabelStyle: {
        color: "#A00E18 !important",
        fontWeight: "300px",
        marginLeft: "5px"
    },
    errorWrapper: {
        paddingBottom: "40px"
    },
    errorMessage: {
        display: "flex",
        color: "#A00E18",
        marginTop: "0.5em",
        "&:before": {
            content: "''",
            display: displayStyle,
            flexShrink: 0,
            width: "29px",
            height: "17px",
            marginTop: "2px",
            background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',
        }
    },
    dateOfBirth: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",


    },
    errorSSNLabel: {
        marginLeft: "39px",
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "0.5em 0px 0px"
    },
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px",
        marginTop: "20px",
        alignItems: "center"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "20px"

    },
    buttonWrapper: {
        [theme.breakpoints.only("sm")]: {
            display: "flex"
        },
        [theme.breakpoints.only('xl')]: {
            display: "flex"
        },
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: "1rem",
            [theme.breakpoints.up("sm")]: {
                display: "unset",
                width: "auto",
                marginBottom: 0,
                marginLeft: "1.5rem",
                "&:first-child": {
                    marginLeft: 0
                }
            }
        }
    },
    textStyle: {
        fontFamily: font,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        color: "#000000"
    },
    confirmTextStyle: {
        fontFamily: font,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px"
    }
}))

const VerifyIdentity = props => {
    
    let {
        setPage, 
        tokenInfo, 
        setSessionTimeoutEnable, 
        systemErrorCount, 
        setSystemErrorCount,
        setProductList,
        errorType,
        maintenanceDetails,
        setErrorScenario,
        setReview,
        setRedirectUrl
    } = props;
    
    let context = useContext(MyContext);
    let { appId } = context;
    let { jwt } = getQueryParams();
    const adobeDataLayer = useAdobeDataLayer();
    
    const [isLoading, setLoading] = useState(false);  
    // const [isPolicyVarificationFailed, setPolicyVarificationFailed] = useState(false);    
  
    const [formValues, setFormValues] = useState({
        policyNumber: ''
    })
    const [errorState, setErrorState] = useState({
        policyNumber: { id: 'policyNumber', errMsg: '', isError: false, warning: false }
    })
    
    useEffect(()=> {
        setSessionTimeoutEnable(true)
    });

    const submit = () => {
        if(!isLoading && validate()){
            // setPolicyVarificationFailed(false);
            resetPolicyError();
            callValidatePolicy();
        }
    }

    
    function callValidatePolicy(){
        
        if(systemErrorCount > 0) setSystemErrorCount(0);
        
        let policyNumber = formValues.policyNumber?.trim();

        if(appId === JsonConfig.app_id.LTC && policyNumber.length > 7){
            policyNumber = policyNumber.substring(policyNumber.length - 7);
        }

        let payload = {
            "token": jwt,
            "guid": tokenInfo.requestid,
            "appid": appId,
            "policynumber": policyNumber
        }
        setLoading(true);
        Service.verifyDetails(payload)
        .then((response)=> {
            setLoading(false)

            let systemErrorCodes = [Constants.CODE_9002, Constants.CODE_9999, Constants.CODE_9014];
            let reviewErrorPageCodes = [Constants.CODE_9060, Constants.CODE_9075];

            adobeDataLayer.registrationComplete(tokenInfo?.username || '');
            if (response.code === Constants.CODE_2001) {
                setProductList(response?.details?.identity);
                setRedirectUrl(response.details.postUrl)
                if (appId === Constants.APP_ID_PDCO) {
                    setPage(Constants.PAGE_SUCCESS_PAGE_PDCO);
                } else {
                    setPage(Constants.PAGE_SUCCESS_PAGE_1);  
                } 
                resetPolicyError();
                setSystemErrorCount(0);
            } else if (reviewErrorPageCodes.includes(response.code)) {
                setReview(true);
                setErrorScenario(true);
            } else if (response.code === Constants.CODE_9009) {
                setReview(false)
                setErrorScenario(true);
                // dataLayer if user enters wrong policy 3 times
                adobeDataLayer.registrationUnsuccessfull('', response.code, 'Invalid policy number');
            } else if (response.code === Constants.CODE_9059) {
                setSystemErrorCount(0);
                setErrorState({...errorState, policyNumber: { id: 'policyNumber', errMsg: "Please enter a valid policy number", isError: true, warning: false }});
                setFormValues({ ...formValues, "policyNumber": "" });
                // dataLayer error
                adobeDataLayer.registrationUnsuccessfull('', response.code, 'Invalid policy number');
            } else if (systemErrorCodes.includes(response.code)) {
                setSystemErrorCount(systemErrorCount+1);
                resetPolicyError();
            } else {
                // Unhandled error codes
                setSystemErrorCount(systemErrorCount+1);
                resetPolicyError();
            }
        }).catch(error => {
            console.log("Error ========>", error);
            setLoading(false);
            setSystemErrorCount(systemErrorCount+1);
            // setPolicyVarificationFailed(false);
            resetPolicyError();
        })
    }

    function resetPolicyError(){
        setErrorState({...errorState, policyNumber: { id: 'policyNumber', errMsg: "", isError: false, warning: false }});
    }

    function validate(){
        
        if(!(formValues.policyNumber && formValues.policyNumber?.trim())){
            let temperrors = {...errorState, policyNumber: { id: 'policyNumber', errMsg: "Enter policy number", isError: true, warning: false }};
            setErrorState(temperrors);
            setFormValues({...formValues, policyNumber: ""});
            return false;
        }
        return true;
    }

    const classes = useStyles();
    
    const changeHandler = (name, value) => {
        setFormValues({ ...formValues, [name]: value })
        setErrorState({policyNumber: { id: 'policyNumber', errMsg: "Enter policy number", isError: !value, warning: true }});
    }
    
    return (
        <div data-testid="verifyIdentity-root" className={classes.root}>
            <Grid container spacing={0}>
                {
                    systemErrorCount > 0 &&
                        <EncounteredError appId={appId} systemErrorCount={systemErrorCount}/>
                }
                {
                    errorType === 'Maintenance' &&
                    <Grid item md={9} xs={12}>
                        <div className={classes.errorWrapper}>
                            <div className={classes.errorIcon} />
                                <span className={classes.errorLabelStyle}>
                                    {
                                        maintenanceDetails ? maintenanceDetails : "The system is currently undergoing scheduled maintenance. If you are experiencing registration issues, please try again later. Thank you for your patience."
                                    }
                                </span>
                        </div>
                    </Grid>
                }
                <Grid item md={9} xs={12} className={classes.gridSeparation}>
                    <div className={classes.thankYouText}>Thank you{tokenInfo?.firstname ? ", "+tokenInfo.firstname+"." : ""}</div>
                    <div className={classes.subtitleText}>Your username <strong>{tokenInfo?.username ? tokenInfo.username : ""}</strong> has been created. Your security is our priority. <br/>Please complete an additional verification step below.</div>
                </Grid>
                <div className={classes.inputContainer}>
                    <Grid item md={12} xs={12} className={gridSeparation+" "+myinput+(errorState.policyNumber.isError && !errorState.policyNumber.warning ? " error" : "")}>
                        <label className={classes.labelStyle}>
                            Policy number
                        </label>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id='policyNumber'
                            data-testid="verifyIdentity-policyNumber"
                            placeholder="Enter policy number"
                            maxLength={50}
                            value={formValues.policyNumber}
                            onChange={e => changeHandler("policyNumber", e)}
                            onBlur={()=> setErrorState({...errorState, policyNumber: { ...errorState.policyNumber, warning: false }})}
                            onKeyPress={(e)=> {
                                if(e.key === "Enter") submit();
                            }}
                            callouts={true}
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                },
                                inputStyle: {
                                    padding: '0px'

                                }
                            }} name="policyNumber" />
                        {errorState.policyNumber.isError && <InputError errorState={errorState} inputId="policyNumber" showIcon={true}/>}
                    </Grid>
                        
                </div>                

                <Grid item md={12} xs={12} className={classes.ctaWrapper}>
                    <Button
                        name="submit button"
                        data-testid="verifyIdentity-submitButton"
                        saving={isLoading}                        
                        id="submitbutton"
                        disabled={isLoading}
                        onClick={e => submit()}>Submit</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default VerifyIdentity