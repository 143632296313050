import React, { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { THEMES } from '@manulife/mux';
import { PageBodyWrapper, Header, Footer } from './components'
import { Grid, Container } from '@material-ui/core';
import { MyContext } from './Context/MyContext';
import { getQueryParams } from './utils';
import LeftNavigationPanel from './components/LeftNavigationPanel/LeftNavigationPanel';
import PDCOHeader from './components/PDCOHeader/PDCOHeader';
import JsonConfig from './utils/jsonConfig.json';
import useAdobeDataLayer from './hooks/useAdobeDataLayer';


function App() {
  
  let [appId, setAppId] = useState("");
  const { addAdobeScript } = useAdobeDataLayer();
  
  let params = getQueryParams();
  
  const [displayPDCOBanner, setDisplayPDCOBanner] = useState(true);

  useEffect(()=> {

    if(params?.appId){
      addAdobeScript()
      setAppId(params.appId);
    }
  }, [params?.appId])

  function iniFrame() {
    if (window.self !== window.top) {
      console.log("In iFrame");
      return true;
    } else {
      console.log("Not in iFrame");
      return false;
    }
  }
  if (iniFrame()) {
    return null
  }

 

  return (
    <ThemeProvider theme={THEMES.johnhancockTheme}>
      <MyContext.Provider value={{appId}}>
      <div class='app-container'>
          <LeftNavigationPanel />
          <div class='body-container'>
          {appId === JsonConfig.app_id.PDCO && (
              <PDCOHeader appId={params.appId} displayPDCOBanner={displayPDCOBanner}/>
            )}
            <Container className="app-content">
              {
                appId !== JsonConfig.app_id.PDCO &&
                  <Grid container spacing={8}>
                    <Grid item xs={8}>
                      <Header appId={appId}/>
                    </Grid>
                  </Grid>
              }
              <PageBodyWrapper setDisplayPDCOBanner={setDisplayPDCOBanner}/>
            </Container>
            <Footer appId={appId}/>
          </div>
        </div>
        </MyContext.Provider>
    </ThemeProvider>
  );
}

export default App;
