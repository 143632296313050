import React from 'react';
import PageBody from './PageBody'


function PageBodyWrapper(setDisplayPDCOBanner) {
  
  return <div data-testid="PageBody-root">
          <PageBody setDisplayPDCOBanner={setDisplayPDCOBanner}/>
        </div>
}

export default PageBodyWrapper;
