
const MESSAGE_API = "/message";
const GET_REG_SOURC_API = "/getregsrc";
const VERIFY_DETAILS_API = "/verifydetails";
const POST_CALL_API = "/postCall";

const URLConstants = {
    MESSAGE_API,
    GET_REG_SOURC_API,
    VERIFY_DETAILS_API,
    POST_CALL_API
};

export default URLConstants;